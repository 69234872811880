<template>
  <v-card flat>
    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
      <v-tab v-for="item in itemsList" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-row v-if="assetsInventory != ''">
            <v-col>
              <v-list-item>نام : {{ assetsInventory[0].user.firstName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام خوانوادگی : {{ assetsInventory[0].user.lastName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام کاربری : {{ assetsInventory[0].user.username }}</v-list-item>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              <div style="display:flex;flex-direction: column;align-items: flex-end" v-if="userInventory">
                <div style="display:flex;justify-content: space-between;width:100%"><span>دارایی به تومان :</span> <span>{{userInventory.totalFundInToman}}</span></div>
                <div style="display:flex;justify-content: space-between;width:100%"><span>دارایی به تتر :</span> <span>{{userInventory.totalFundInTether}}</span></div>
                <div style="display:flex;justify-content: space-between;width:100%"><span>قابل معامله به تومان :</span> <span>{{userInventory.totalTradableInToman}}</span></div>
                <div style="display:flex;justify-content: space-between;width:100%"><span>قابل معامله به تتر :</span> <span>{{userInventory.totalTradableInTether}}</span></div>
              </div>
              <v-spacer></v-spacer>

              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" outlined hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerInventory" :items="assetsInventory" :search="search">
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-tab-item>

      <v-tab-item>
        <v-card-title>
          گردش حساب کاربر
          <v-spacer></v-spacer>
        </v-card-title>
        <div class="row" style="margin:2rem 0 1rem 0;justify-content: flex-start;">
          <div class="col-2" style="text-align: left;max-width: 100px;margin-top: 6px;">از تاریخ:</div>
          <div class="col-3" style="max-width: 250px;">
            <satrex-date-picker v-model="fromDateTime"></satrex-date-picker>
          </div>
          <div class="col-2" style="text-align: left;max-width: 100px;margin-top: 6px;">تا تاریخ:</div>
          <div class="col-3" style="max-width: 250px;">
            <satrex-date-picker v-model="toDateTime"></satrex-date-picker>
          </div>
          <div class="col-2">
            <button class="greenButton" style="margin-right:2rem;" @click="getTurnOver()">فیلتر تاریخ</button>
          </div>
        </div>

        <v-data-table :headers="headers" :items="turnOver" :search="search" hide-default-footer disable-pagination>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
          <template v-slot:item.createdAtUtc="{ item }">
            <v-row>
              <span>{{getPersianDate(item.createdAtUtc)}}</span>
            </v-row>
          </template>
        </v-data-table>
        <template>
          <div class="text-center">
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination v-model="page" class="my-4" :length="paginationInfo.pageCount"></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </template>
      </v-tab-item>
      <v-tab-item>
        <v-tabs v-model="ordersTab" background-color="transparent" color="basil" grow>
          <v-tab v-for="item in ordersTabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="ordersTab">
          <v-tab-item>
            <v-data-table :headers="ordersHeaders" :items="userOrders.orders" hide-default-footer disable-pagination items-per-page="100">
              <template v-slot:item.createdAtUtc="{ item }">
                <v-row>
                  <span>{{getPersianDate(item.createdAtUtc)}}</span>
                </v-row>
              </template>
              <template v-slot:item.id="{ item }">
                <v-row>
                  <span>{{item.id}} </span>
                </v-row>
              </template>
            </v-data-table>
            <v-pagination v-model="pageOrder" :length="paginationInfoOrder.pageCount"></v-pagination>
          </v-tab-item>
          <v-tab-item>
            <v-data-table :headers="fastOrdersHeaders" :items="fastOrders"  hide-default-footer disable-pagination>
              <template v-slot:item.createdAtUtc="{ item }">
                <v-row>
                  <span>{{getPersianDate(item.createdAtUtc)}}</span>
                </v-row>
              </template>
              <template v-slot:item.completedAtUtc="{ item }">
                <v-row>
                  <span>{{getPersianDate(item.completedAtUtc)}}</span>
                </v-row>
              </template>

            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

// import Vue from "vue";
import getPersianDateTime from "@/plugins/getPersianDate";
import axiosApi from "@/axios";
import moment from "jalali-moment";
import convertDate from "@/plugins/convertDate";

export default {
name: "GetTransactions",
  data(){
    return {
      headers: [
        {
          text: 'موجودی کل',
          align: 'start',
          value: 'newTotalFund',
        },
        {text: 'موجودی قابل معامله', value: 'newTotalTradable'},
        {text: ' نماد', value: 'assetSymbol'},
        {text: 'نوع معامله', value: 'transactionEntry'},
        {text: 'درخواست برداشت داخلی', value: 'internalWithdrawRequestId'},
        {text: 'پرداخت خارجی', value: 'externalPaymentId'},
        {text: 'درخواست خرید فروش سریع', value: 'fastExchangeRequestId'},
        {text: 'درخواست برداشت خارجی', value: 'externalWithdrawRequestId'},
        {text: 'پرداخت خارجی', value: 'externalPaymentId'},
        {text: 'تاریخ ثبت ', value: 'createdAtUtc'},
      ],
      headerInventory: [
        { text: 'نام', value: 'asset.persianTitle' },
        { text: 'نماد', value: 'asset.symbol' },
        { text: 'دارایی', value: 'totalFund' },
        { text: 'دارایی قابل معامله', value: 'totalTradable' },
      ],
      turnOver : [],
      paginationInfo : '',
      page : 0,
      orderPageCount : '',
      search : '',
      assetsInventory: [],
      assetIt : '',
      fromDateTime : '',
      pageOrder : '',
      toDateTime : '',
      paginationInfoOrder: [],
      transactionEntry : '',
      tab: null,
      ordersTab:null,
      userInventory:null,
      itemsList: ['کیف پول', 'گردش حساب', 'معاملات'],
      ordersTabs: ['سفارشات', 'معاملات سریع'],
      ordersHeaders:[
        {text:'شناسه',value:'id'},
        {text:'وضعیت',value:'orderStatus'},
        {text:'نوع',value:'orderType'},
        {text:'قیمت',value:'price'},
        {text:'نوع مارکت',value:'marketOrderType'},
        {text:'مقدار کل',value:'totalVolume'},
        {text:'مقدار باقیمانده',value:'remainVolume'},
        {text:'بازار',value:'pair.symbol'},
        {text: 'میانگین قیمت',value:'averagePrice'},
        {text: 'تاریخ',value:'createdAtUtc'},
      ],
      fastOrdersHeaders:[
        {text:'شناسه',value:'id'},
        {text: 'نماد',value: 'asset.persianTitle'},
        {text: 'کاربر',value: 'user.username'},
        {text: 'قیمت',value: 'price'},
        {text: 'نوع سفارش',value: 'orderType'},
        {text: 'میزان سفارش',value: 'requestedVolume'},
        {text: 'میزان واریز',value: 'depositVolume'},
        {text: 'تغییر دهنده',value: 'modifier.username'},
        {text: 'آخرین وضعیت',value: 'lastTransactionConfirmationType'},
        {text: 'تاریخ کامل شدن',value: 'completedAtUtc'},
        {text: 'تاریخ ثبت سفارش',value: 'createdAtUtc'},
      ],
      userOrders:[],
      fastOrders:[],
    }
  },
  mounted() {
    this.getTurnOver()
    // this.getDetailAccount()
    this.getAssetsInventory()
    this.getAllUserOrders()
    this.getUserFastOrders()
    this.getUserInventory()
  },
  watch: {
    page () {
      this.getTurnOver()
    },
    pageOrder()
    {
      this.getAllUserOrders()
    }
  },
  methods:{
    getAssetsInventory() {
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: this.$route.params.username
      }).then(({ data }) => {
        this.assetsInventory = data.data;
      });
    },
    getTurnOver(){
      axiosApi().post('/api/Manage/v1/TurnOver/GetTransactions',{
        username :  this.$route.params.username,
        pageIndex : this.page,
        pageSize : 100,
        transactionEntry : this.transactionEntry === '' ? null : this.transactionEntry,
        fromDateTime : this.fromDateTime === '' ? null : convertDate(this.fromDateTime) ,
        toDateTime : this.toDateTime === '' ? null : convertDate(this.toDateTime),
        sortOrder: "Descending",
      })
          .then(({data}) => {
            if (data['isSuccess'])
              this.turnOver = data.data.transactions
              this.paginationInfo = data.data.paginationInfo
          })
    },
    getPersianDate(data) {
      return  getPersianDateTime(data)
    },
    getUserInventory() {
      axiosApi().post('/api/Manage/v1/Wallet/GetUserTotalAssetsPriceInTomanAndTether', {
        username: this.$route.params.username
      })
          .then(({data}) => {
            this.userInventory = data.data
          })
    },
    getDate(date) {
      return moment(date).locale('fa').format('jYYYY-jMM-jDD')
    },
    // getDetailAccount(){
    //   axiosApi().post('/api/Manage/v1/Account/Get',{
    //     username :  this.$route.params.username
    //   })
    // },
    getAllUserOrders(){
      axiosApi().post('/api/Manage/v1/Order/GetUserOrders',{
        username :  this.$route.params.username,
        pageIndex : this.pageOrder,
        orderPageCount : '',
        pageSize : 100,
      })
          .then(({data}) => {
            this.userOrders = data.data
            this.paginationInfoOrder = data.data.paginationInfo
          })
    },
    getUserFastOrders(){
      axiosApi().post('/api/Manage/v1/FastExchange/GetList',{
        username :  this.$route.params.username,
        pageIndex : this.page,
        pageSize : 20,
      })
          .then(({data}) => {
            this.fastOrders = data.data
          })
    },
  }

}
</script>

<style scoped>

</style>
